import { HttpMutators } from "./http-mutators";
import { useHttp } from "./http";

export type ChangePasswordPayload = {
  newPassword: string;
};

export const useUserService = () => {
  const { get, post, patch, remove } = useHttp("/users");

  return {
    getUsers: (mutators: HttpMutators, params?: any) =>
      get(mutators, { url: "/all", params }),
    getUserById: (mutators: HttpMutators, id: string) =>
      get(mutators, { url: `/${id}` }),
    getUsersByProperty: (
      mutators: HttpMutators,
      payload: any,
      params?: any
    ) => {
      // query params: sort=xp:-1 to sort by xp in descending order
      return post(mutators, { url: "/property?sort=xp:-1", payload, params });
    },
    getProfile: (mutators: HttpMutators) => get(mutators, { url: "/who-am-i" }),
    updateCurrentUser: (mutators: HttpMutators, payload: any) =>
      patch(mutators, { payload }),
    updateUser: (mutators: HttpMutators, id: string, payload: any) =>
      patch(mutators, { url: `/${id}`, payload }),
    deleteUser: (mutators: HttpMutators, id: string) =>
      remove(mutators, { url: `/${id}/delete` }),
    getUsersBornThisWeek: (mutators: HttpMutators, params?: any) =>
      get(mutators, { url: "/birthday-current-week", params }),
    sendWarningToUser: (mutators: HttpMutators, payload: any) =>
      post(mutators, { url: `/warnings`, payload }),
    getWarningsByUser: (mutators: HttpMutators, id: string) =>
      get(mutators, { url: `/${id}/warnings` }),
    /* Stats */
    getUsersLastActivity: (mutators: HttpMutators, params?: any) =>
      get(mutators, { url: "/stats/last-activity", params }),
    getUsersRegistrationByMonth: (mutators: HttpMutators, params?: any) =>
      get(mutators, { url: "/stats/registration-by-month", params }),
    exportUsersData: (mutators: HttpMutators) =>
      get(mutators, {
        url: "/stats/export",
        responseType: "blob",
        // We need to transform the response to a blob to be able to download it
        transformResponse: (response) => {
          const blob = new Blob([response], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "users-data.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
          return response;
        },
      }),
  };
};
