import Decimal from "decimal.js";
import { StaffRolesLabel, UserRolesLabel, UserRoles } from "../types";
import { StaffRoles } from "../types";

const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

export function firstLetterUpperCase(str: string) {
  // Apparently, the string can be undefined.... need to check legacy code if it's defined before using it.. but for now, just return empty string if undefined
  if (!str) return "";
  return str[0] + str.substring(1).toLowerCase();
}

export function getMonthName(index: number) {
  return months[index];
}

export function convertStringDecimalToNumber(str: string | undefined) {
  if (!str) return 0;
  return new Decimal(str).toNumber();
}

export function formatDecimalNumber(value: number) {
  if (value === 0) return 0;
  if (value < 1 && value > -1) {
    if (value > 0) return " ≃ 1";
    else return "- ≃ 1";
  }

  if (Math.abs(value % 1) > 0) return value.toFixed(2);
  return value;
}

// Apparently, the role can be undefined... need to check legacy code if it's defined before using it.. but for now, just return empty string if undefined
export function getUserRolesLabel(role?: UserRoles, staffRoles?: StaffRoles[]) {
  if (!role) return "";
  if (role === UserRoles.STAFF && staffRoles) {
    return staffRoles.map((role) => StaffRolesLabel[role]).join(", ");
  }
  return UserRolesLabel[role];
}
