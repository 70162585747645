import { createStyles, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import UserRegistrationDateChart from "./UserRegistrationDateChart";

export default function Statistics() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <div className={classes.rootContainer}>
      <Title order={2}>{t("statistics.title")}</Title>
      <UserRegistrationDateChart />
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  rootContainer: {
    display: "flex",
    marginLeft: "35vh",
    flexDirection: "column",
    height: "100%",
    padding: 40,
    paddingTop: 20,
    gap: 20,
  },
}));
