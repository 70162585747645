import { Loader, Title } from "@mantine/core";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { fr } from "date-fns/locale"; // Import French locale if you want French formatting
import { addMonths, format, isSameMonth, parse } from "date-fns";
import { useUserService } from "../../../services";
import { useEffect, useState } from "react";

const UserRegistrationDateChart = () => {
  const { getUsersRegistrationByMonth } = useUserService();
  const [isLoading, setIsLoading] = useState(false);
  const [usersRegistrationByMonthData, setUsersRegistrationByMonthData] =
    useState<{ period: string; total: number }[]>([]);

  useEffect(() => {
    setIsLoading(true);
    getUsersRegistrationByMonth({
      error: console.error,
      success: (res) => {
        // Format the empty month with 0
        const filledData = fillMissingMonths(res);
        setUsersRegistrationByMonthData(filledData);
      },
    });
    setIsLoading(false);
  }, []);

  const formatDateLabel = (date: string) => {
    const parsedDate = parse(date, "yyyy-MM", new Date());
    return format(parsedDate, "MMM yyyy", { locale: fr });
  };

  return (
    <>
      <Title order={4} align="center">
        Nombre d&apos;inscriptions
      </Title>
      {isLoading && <Loader size="sm" style={{ margin: "0 auto" }} />}
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={300}
          data={usersRegistrationByMonthData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="period"
            interval={0} // Show all labels
            angle={-45} // Rotate labels for better readability
            textAnchor="end"
            height={70} // Increase height to accommodate rotated labels
            style={{ fontSize: "10px" }}
            tickFormatter={(value) => formatDateLabel(value)}
          />
          <YAxis />
          <Tooltip labelFormatter={(value) => formatDateLabel(value)} />
          <Legend />
          <Area
            type="monotone"
            dataKey="total"
            name="Inscriptions"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

const fillMissingMonths = (data: { period: string; total: number }[]) => {
  if (data.length === 0) return [];

  const result = [];
  const startDate = parse(data[0].period, "yyyy-MM", new Date());
  const endDate = parse(data[data.length - 1].period, "yyyy-MM", new Date());
  let currentDate = startDate;
  let dataIndex = 0;

  while (true) {
    // Loop until the end date
    const formattedDate = format(currentDate, "yyyy-MM");

    if (data[dataIndex].period === formattedDate) {
      result.push({
        period: formattedDate,
        total: data[dataIndex].total,
      });
      dataIndex++;
    } else {
      result.push({
        period: formattedDate,
        total: 0,
      });
    }

    if (isSameMonth(currentDate, endDate)) break;
    currentDate = addMonths(currentDate, 1);
  }

  return result;
};

export default UserRegistrationDateChart;
